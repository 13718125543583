import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { SEARCH } from '@/data/navbar/navButtons';
import Button from '@/designSystem/Button/Button';
import LoginSignUpModalWrapper from '@/shared/LoginSignupModalWrapper/LoginSignupModalWrapper';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import { Origin } from '@/types/onboardingInfo';

import messages from './messages';

const LoginButton: FC = () => {
  const { query, asPath } = useRouter();
  const { isConnected, route } = useNavbarContext();
  const { formatMessage } = useIntl();
  const [hasLoginError, setHasLoginError] = useState(false);

  useEffect(() => {
    setHasLoginError(!!query.loginModalError);
  }, [query]);

  const handleClick = (openLoginModal: () => void) => {
    window.dataLayer.push({
      event: 'header_menu',
      menu: messages.login.defaultMessage,
    });
    if (!isConnected) {
      openLoginModal();
    }
    window.dataLayer.push({
      event: 'sign_in',
    });
  };

  const getOrigin = (): Origin => {
    if (route === '/t/[seoId]') {
      return 'announceNavbar';
    }
    if (query.fromBlog === 'true') {
      return 'fromBlog';
    }
    if (query.fromUnknown === 'true') {
      return 'fromUnknown';
    }
    return 'landingNavbar';
  };

  return (
    <LoginSignUpModalWrapper
      initialVariant="login"
      renderItem={({ toggle }) => (
        <Button
          data-testid="navbar-login-button"
          variant="secondary"
          onClick={() => handleClick(toggle)}
          label={formatMessage(messages.login)}
        />
      )}
      origin={getOrigin()}
      drawerExitUrl={route === '/t/[seoId]' ? asPath : SEARCH}
      hasLoginError={hasLoginError}
      setHasLoginError={setHasLoginError}
    />
  );
};

export default LoginButton;
