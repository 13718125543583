import { normalizeString } from '@les-sherpas/sherpas-toolbox';
import { baseUrl } from 'config';
import { useRouter } from 'next/router';

export const useCanonicalUrl = () => {
  const router = useRouter();

  const urlWithoutSearchParams = router.asPath.split('?')[0];
  const rawUrl = new URL(urlWithoutSearchParams, baseUrl);
  const decodedUrl = decodeURIComponent(rawUrl.href);
  const canonicalUrl = normalizeString(decodedUrl);

  return canonicalUrl;
};
