import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconSupport: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16 10C16 10.9926 15.759 11.9289 15.3322 12.7536L13.8076 11.229C13.9325 10.8418 14 10.4288 14 10C14 9.67136 13.9604 9.35198 13.8856 9.04638L15.4484 7.4836C15.8024 8.24889 16 9.10137 16 10ZM10.8346 13.9128L12.4156 15.4939C11.6766 15.8193 10.8594 16 10 16C9.10137 16 8.24889 15.8024 7.4836 15.4484L9.04638 13.8856C9.35198 13.9604 9.67136 14 10 14C10.2862 14 10.5654 13.9699 10.8346 13.9128ZM6.15807 11.1171C6.05516 10.7626 6 10.3877 6 10C6 9.66808 6.04043 9.3456 6.11663 9.03722L6.0378 9.11604L4.50611 7.58436C4.18068 8.32343 4 9.14061 4 10C4 10.9539 4.22258 11.8557 4.61864 12.6565L6.15807 11.1171ZM7.24639 4.66778C8.07107 4.24104 9.00739 4 10 4C10.9539 4 11.8557 4.22258 12.6565 4.61864L11.1171 6.15807C10.7626 6.05516 10.3877 6 10 6C9.57122 6 9.1582 6.06747 8.77097 6.19236L7.24639 4.66778ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
    />
  </BaseSvg>
);

export default IconSupport;
